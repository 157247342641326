import { IArtworkSet } from "@maxeb/art-sdk";
import { State, initialState, Props } from "./State";

import { useNavigate } from "@maxeb/admin-ui";
import { useEffect } from "react";
import * as Mutations from "./Mutations";
import {
  getCurrentLocation,
  getSortedLocations,
  locationToObject,
} from "./Helper";
import useSaveState from "../../SaveState";

export type { State, Props } from "./State";
export interface Actions {
  ok: () => void;
  pending: () => void;
  reload: () => void;
  setData: (data: State["data"]) => void;
  saveData: () => void;
  addOwner: (owner: string) => void;
  removeOwner: (owner: string) => void;
  addLocation: (location: string, latest?: string) => void;
  removeLocation: (location: string, latest?: string) => void;
  addCondition: (condition: string) => void;
  removeCondition: (condition: string) => void;
  setCollection: (collection: string) => void;
  setConfirmDelete: (to: boolean) => void;
  remove: () => void;
}

export function useArtwork(props: Props): [State, Actions] {
  const { id, collection } = props;

  const navigate = useNavigate();

  const [state, setState] = useSaveState(initialState(props));

  useEffect(() => {
    if (state.status === "init") {
      Mutations.get(state, setState);
    }

    const rewriteURL =
      collection &&
      id &&
      window.location.href.endsWith &&
      window.location.href.endsWith("/artworks");

    if (rewriteURL) {
      window.history.pushState({}, "", `/artworks/${collection}/${id}`);
    }
  }, [state, setState, collection, id]);

  return [
    state,
    {
      setConfirmDelete: (to: boolean) => {
        setState({ ...state, confirmDelete: to });
      },
      remove: () => {
        Mutations.remove({ navigate }, state, setState);
      },
      ok: () => {
        Mutations.setOk(state, setState);
      },
      pending: () => {
        Mutations.setPending(state, setState);
      },
      reload: () => {
        Mutations.setReload(state, setState);
      },
      setData: (data) => {
        Mutations.setData({ data }, state, setState);
      },
      saveData: () => {
        Mutations.saveData(state, setState);
      },
      addOwner: (owner: string) => {
        const set: IArtworkSet = {
          owner: {
            "!add": [owner],
          },
        };

        return Mutations.save({ set }, state, setState);
      },
      removeOwner: (owner: string) => {
        const set: IArtworkSet = {
          owner: {
            "!delete": [owner],
          },
        };

        return Mutations.save({ set }, state, setState);
      },
      addLocation: (location: string) => {
        const latest = getSortedLocations(state.data.location)[0];

        const set: IArtworkSet = {
          location: {
            "!add": [location],
          },
          currentLocation: locationToObject(
            getCurrentLocation(location, latest)
          ).location,
        };

        return Mutations.save({ set }, state, setState);
      },
      removeLocation: (location: string) => {
        const latest = getSortedLocations(state.data.location)[0];

        const set: IArtworkSet = {
          location: {
            "!delete": [location],
          },
          currentLocation: latest ? locationToObject(latest).location : null,
        };

        return Mutations.save({ set }, state, setState);
      },
      addCondition: (condition: string) => {
        const set: IArtworkSet = {
          condition: {
            "!add": [condition],
          },
        };

        return Mutations.save({ set }, state, setState);
      },
      removeCondition: (condition: string) => {
        const set: IArtworkSet = {
          condition: {
            "!delete": [condition],
          },
        };

        return Mutations.save({ set }, state, setState);
      },
      setCollection: (collection: string) => {
        return Mutations.save(
          { set: { collection, artistId: state.data.artistId } },
          state,
          setState
        );
      },
    },
  ];
}
