import React, { useState } from "react";
import {
  Button,
  ITheme,
  useClasses,
  useI18n,
  Row,
  Col,
  Table,
  Checkbox,
  Select,
  Typo,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import IsVisble from "../../Shared/IsVisible";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { State, Actions } from "../../../Hooks/Artworks/List";
import BulkDelete from "./Bulk/Delete";
import BulkExport from "./Bulk/Export";
import BulkUpdate from "./Bulk/Update";

export interface IProps {
  status: string;
  useArtworks: [State, Actions];
  team: string;
}

const styles = (theme: ITheme) => ({
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "5rem",
    textAlign: "center",
    padding: "32px",
  },
  checkbox: {
    top: "-5px !important",
    marginTop: "2px !important",
    border: "3px solid " + theme.palette.get("darkGrey") + " !important",
  },
  select: {
    backgroundColor: "#fff !important",
    border: "1px solid " + theme.palette.get("darkGrey") + " !important",
    textAlign: "left !important",
  },
  align: {
    textAlign: "left !important",
  },
  tableSelect: {
    width: "15px",
    padding: "4px 8px !important",
    textAlign: "left !important",
  },
  tableBTNs: {
    width: "120px",
    textAlign: "right !important",
    padding: "4px 8px !important",
  },
});

export default function Artworks(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artworks");

  const { useArtworks, status } = props;
  const [artworks, actions] = useArtworks;
  const [toEdit, setToEdit] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);

  const [bulkAction, setBulkAction] = useState<
    "delete" | "export" | "update"
  >();

  return (
    <>
      <Table
        columns={[
          {
            label: (
              <Checkbox
                inline
                label=""
                value={allSelected}
                override={{ indicator: classes.checkbox }}
                onChange={(value) => {
                  if (value) {
                    setToEdit(actions.filter().map((item) => item.id || ""));
                    setAllSelected(true);
                  } else {
                    setToEdit([]);
                    setAllSelected(false);
                  }
                }}
              />
            ),
            field: "actions",
            classes: classes.tableSelect,
          },
          { label: i18n.get("id"), field: "customId" },
          { label: i18n.get("artist"), field: "artist" },
          { label: i18n.get("title"), field: "title" },
          { label: i18n.get("location"), field: "currentLocation" },
          { label: i18n.get("size"), field: "size" },
          { label: i18n.get("price"), field: "sellingPrice" },
          {
            label: (
              <div className={classes.align}>
                <Select
                  inline
                  label={i18n.get("actions")}
                  options={[
                    {
                      label: i18n.get("export"),
                      value: "export",
                    },
                    {
                      label: i18n.get("delete"),
                      value: "delete",
                    },
                    {
                      label: i18n.get("update"),
                      value: "update",
                    },
                  ]}
                  override={{
                    input: classes.select,
                  }}
                  onChange={(value) => {
                    if (
                      value === "delete" ||
                      value === "export" ||
                      value === "update"
                    )
                      setBulkAction(value);
                  }}
                />
              </div>
            ),
            field: "edit",
            classes: classes.tableBTNs,
          },
        ]}
        data={actions.filter().map((artwork, index) => ({
          actions: (
            <Checkbox
              inline
              label=""
              value={toEdit.indexOf(artwork.id || "") > -1}
              override={{ indicator: classes.checkbox }}
              onChange={(value) => {
                if (value) {
                  const newEdit = [...toEdit, artwork.id || ""];
                  setToEdit(newEdit);
                } else {
                  const newEdit = toEdit.filter((item) => item !== artwork.id);
                  setToEdit(newEdit);
                }
              }}
            />
          ),
          customId: artwork.customId,
          artist: artwork.artist,
          title: artwork.title,
          currentLocation: artwork.currentLocation,
          size: artwork.size,
          sellingPrice: artwork.sellingPrice,
          edit: (
            <Button
              primary
              width="100px"
              onClick={() => {
                actions.active(artwork, artwork.index || index);
                window.history.pushState(
                  {},
                  "",
                  `/artworks/${artwork.collection}/${artwork.id}`
                );
              }}
            >
              <FontAwesomeIcon icon={faEdit} /> Edit
            </Button>
          ),
        }))}
      />
      {status === "ok" && artworks.data.length === 0 && (
        <Row>
          <Col lg={5}>
            <Typo variant="h1">{i18n.get("no_artworks")}</Typo>
            <Typo variant="p">{i18n.get("no_artworks_desc")}</Typo>
          </Col>
        </Row>
      )}
      <Row>
        {status === "pending" && (
          <Col override={{ col: classes.pending }}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </Col>
        )}
        <Col>
          <IsVisble
            onChange={(isVisible) => {
              if (isVisible && status === "ok" && artworks.next) actions.next();
            }}
          />
        </Col>
        {bulkAction === "delete" && (
          <BulkDelete
            toDelete={toEdit}
            collection={artworks.search.collection}
            close={() => {
              setBulkAction(undefined);
            }}
            onFinished={(removed: string[]) => {
              actions.remove(removed);
            }}
          />
        )}
        {bulkAction === "export" && (
          <BulkExport
            toExport={toEdit}
            collection={artworks.search.collection}
            close={() => {
              setBulkAction(undefined);
            }}
          />
        )}
        {bulkAction === "update" && (
          <BulkUpdate
            toEdit={toEdit}
            collection={artworks.search.collection}
            close={() => {
              setBulkAction(undefined);
            }}
            team={props.team}
            onFinished={function (
              toRemove: string[],
              collection: string
            ): void {
              if (collection !== artworks.search.collection)
                actions.remove(toRemove);
            }}
          />
        )}
      </Row>
    </>
  );
}
