import React, { useEffect, useState } from "react";
import {
  ITheme,
  useClasses,
  SelectCustom,
  ICol,
  useI18n,
} from "@maxeb/admin-ui";
import { ISelct } from "@maxeb/admin-ui/lib/components/Forms/SelectCustom";
import { ICategory, Collection } from "@maxeb/art-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

export interface IProps extends ICol {
  value?: string;
  onChange: (value: string) => void;
  override?: ISelct["override"];
}

export interface IState {
  status: "init" | "ok" | "pending" | "error" | "success";
  search: { open: boolean; name?: string; direction: "ASC" | "DESC" };
  data: ICategory[];
  value?: string;
}

const styles = (theme: ITheme) => ({
  curtain: {
    zIndex: "201 !important",
  },
  list: {
    padding: "0px",
    margin: "0px",
    listStyle: "none",
  },
  listElement: {
    cursor: "pointer",
    height: "32px",
    lineHeight: "32px",
    margin: "0px -16px",
    padding: "5px 24px  5px 24px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: theme.palette.get("primary"),
      color: "#fff",
    },
  },
  pending: {
    fontSize: "4rem",
    textAlign: "center",
    color: theme.palette.get("primary"),
  },
});

let MOUNTED = false;

export async function init(
  props: IProps,
  state: IState,
  setState: (state: IState) => void
) {
  setState({ ...state, status: "pending" });
  try {
    const result = await Collection.get(
      {},
      ["id", "name", "thumb", "team"],
      20,
      "secondaryIndex",
      state.search.direction
    );

    if (MOUNTED && result.isSuccess()) {
      const value = result.body.data.find(
        (element: ICategory) => props.value === element.id
      )?.name;

      return setState({
        ...state,
        status: "ok",
        data: result.body.data,
        value: value || state.value,
      });
    }
  } catch (err) {
    console.error(err);
  }
  if (MOUNTED) setState({ ...state, status: "error" });
}

export default function ArtistsSelect(props: IProps) {
  const classes = useClasses(styles);

  const i18n = useI18n("collection_select");
  const [open, setOpen] = useState(false);

  const [state, setState] = useState<IState>({
    status: "init",
    search: { open: false, direction: "ASC" },
    data: [],
    value: i18n.get("no_collection"),
  });

  const { status, data } = state;

  useEffect(() => {
    MOUNTED = true;
    if (status === "init") init(props, state, setState);

    return () => {
      MOUNTED = false;
    };
  }, [props, state, status]);

  return (
    <SelectCustom
      inline
      label="Collections"
      {...props}
      open={open}
      value={state.value}
      setOpen={() => {
        setOpen(!open);
      }}
      override={{ ...(props.override || {}), curtain: classes.curtain }}
    >
      <ul className={classes.list}>
        <li
          className={classes.listElement}
          onClick={() => {
            setState({ ...state, value: i18n.get("no_collection") });
            props.onChange("");
          }}
        >
          {i18n.get("no_collection")}
        </li>
        {data.map((category) => (
          <li
            key={`artist-${category.id}`}
            className={classes.listElement}
            onClick={() => {
              setState({ ...state, value: category.name });
              props.onChange(category.id || "");
            }}
          >
            {category.name}
          </li>
        ))}
        {(state.status === "init" || state.status === "pending") && (
          <li className={classes.pending}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </li>
        )}
      </ul>
    </SelectCustom>
  );
}
