import React, { useEffect, useState } from "react";
import { useI18n, LightBox, Typo, Row, Button } from "@maxeb/admin-ui";
import ProgressSpinner from "../../../Shared/ProgressSpinner";
import { Artwork } from "@maxeb/art-sdk";

export interface IProps {
  collection: string;
  close: () => void;
  toDelete: string[];
  onFinished: (removed: string[]) => void;
}

export interface IState {
  at: number;
  finished: boolean;
  removed: string[];
}

let MOUNTED = true;
const DELAY = 1000;

async function remove(
  state: IState,
  setState: (state: IState) => void,
  collection: string,
  toDelete: string[],
  onFinished: (removed: string[]) => void
) {
  const newState = { ...state };
  const id = toDelete[state.at];
  try {
    const response = await Artwork.remove({
      id,
      collection: collection,
    });
    if (response.isSuccess()) newState.removed.push("");
  } catch (error) {}

  if (MOUNTED) {
    newState.at++;
    const finished = !(newState.at < toDelete.length);

    setState({ ...newState, finished });

    if (!finished)
      setTimeout(
        () => remove(newState, setState, collection, toDelete, onFinished),
        DELAY
      );
    else onFinished(toDelete);
  }
}

export default function Add(props: IProps) {
  const i18n = useI18n("artworks_bulk_delete");

  const [confirmation, setConfirmation] = useState(false);
  const [state, setState] = useState<IState>({
    at: 0,
    finished: false,
    removed: [],
  });

  const hasElements = props.toDelete.length > 0;

  useEffect(() => {
    MOUNTED = true;
    return () => {
      MOUNTED = false;
    };
  });

  return (
    <LightBox
      variant="danger"
      title={i18n.get("title")}
      open={true}
      onClose={props.close}
    >
      {!hasElements && (
        <>
          <Typo variant="h1">{i18n.get("no_title")}</Typo>
          <Typo variant="p">{i18n.get("no_desc")}</Typo>
          <Row horizontalAlign="right">
            <Button
              xs="100px"
              onClick={() => {
                props.close();
              }}
            >
              {i18n.get("close")}
            </Button>
          </Row>
        </>
      )}
      {hasElements && !confirmation && (
        <>
          <Typo variant="h1">{i18n.get("delete")}?</Typo>
          <Typo variant="p">
            {i18n.get("do_you_really_want")} <b>{props.toDelete.length}</b>{" "}
            {i18n.get("artworks")}.
          </Typo>
          <Row horizontalAlign="right" spacing={8}>
            <Button
              xs="100px"
              onClick={() => {
                props.close();
              }}
            >
              {i18n.get("close")}
            </Button>
            <Button
              xs="100px"
              danger
              onClick={() => {
                setConfirmation(true);
                remove(
                  state,
                  setState,
                  props.collection,
                  props.toDelete,
                  props.onFinished
                );
              }}
            >
              {i18n.get("delete")}
            </Button>
          </Row>
        </>
      )}
      {hasElements && confirmation && !state.finished && (
        <>
          <Typo variant="h1">{i18n.get("deleting")}...</Typo>
          <Row>
            <ProgressSpinner at={state.at} max={props.toDelete.length} />
          </Row>
          <Row horizontalAlign="right" spacing={8}>
            <Button
              xs="100px"
              onClick={() => {
                props.close();
              }}
            >
              {i18n.get("cancel")}
            </Button>
          </Row>
        </>
      )}
      {hasElements && confirmation && state.finished && (
        <>
          <Typo variant="h1">{i18n.get("finished")}!</Typo>
          <Typo variant="p">{i18n.get("finished_desc")}.</Typo>
          <Row horizontalAlign="right" spacing={8}>
            <Button
              xs="100px"
              onClick={() => {
                props.close();
              }}
            >
              {i18n.get("close")}
            </Button>
          </Row>
        </>
      )}
    </LightBox>
  );
}
