import React, { useState } from "react";
import {
  useI18n,
  Typo,
  Row,
  Button,
  ITheme,
  useClasses,
} from "@maxeb/admin-ui";
import CollectionSelect from "../../../Collections/Select";

export interface IProps {
  onChange: (collection: string) => void;
  onCancel: () => void;
}

const styles = (theme: ITheme) => ({
  collection: {
    padding: "16px 0px 0px 0px",
  },
  collectionDesc: {
    padding: "0px 0px 8px 0px",
  },
});

export default function BulkCollection(props: IProps) {
  const i18n = useI18n("artworks_bulk_update");
  const classes = useClasses(styles);

  const [state, setState] = useState<string>("");

  return (
    <>
      <Typo variant="p">{i18n.get("collection_desc")}</Typo>
      <div className={classes.collection}>
        <CollectionSelect
          onChange={function (value: string): void {
            setState(value);
          }}
        />
        <Row
          horizontalAlign="right"
          root={{ top: 16, left: 0, right: 0, bottom: 0 }}
          spacing={16}
        >
          <Button
            xs="120px"
            onClick={() => {
              props.onCancel();
            }}
          >
            {i18n.get("cancel")}
          </Button>
          <Button
            xs="120px"
            success
            onClick={() => {
              props.onChange(state);
            }}
          >
            {i18n.get("move")}
          </Button>
        </Row>
      </div>
    </>
  );
}
