import React from "react";
import { ITheme, useClasses } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

const styles = (theme: ITheme) => ({
  state: {
    position: "relative",
    fontSize: "7rem",
    lineHeight: "7rem",
    color: theme.palette.get("primary"),
    display: "inline-block",
  },
  stateTxt: {
    position: "absolute",
    left: 0,
    width: "115px",
    textAlign: "center",
    color: theme.palette.get("font"),
    fontSize: "2rem",
  },
  progressHolder: { textAlign: "center" },
  successCol: {
    textAlign: "center",
  },
});

export interface IProps {
  max: number;
  at: number;
}

export default function TopBar(props: IProps) {
  const classes = useClasses(styles);

  const { at, max } = props;

  return (
    <div className={classes.progressHolder}>
      <div className={classes.state}>
        <FontAwesomeIcon icon={faSpinnerThird} spin />
        <span className={classes.stateTxt}>
          {Math.min(Math.floor((100 / max) * at), 99)}%
        </span>
      </div>
    </div>
  );
}
