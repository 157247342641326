import React, { useEffect, useState } from "react";
import { useI18n, LightBox, Typo, Row, Button } from "@maxeb/admin-ui";
import ProgressSpinner from "../../../Shared/ProgressSpinner";
import { Artwork, IArtworkSet } from "@maxeb/art-sdk";
import Tabs from "../../../Shared/Tabs";
import NewOwner from "../../Edit/Owner/New";
import NewLocation from "../../Edit/Location/New";
import {
  getCurrentLocation,
  getSortedLocations,
  locationToObject,
} from "../../../../Hooks/Artworks/Edit/Helper";
import BulkCollection from "./Collection";

export interface IProps {
  collection: string;
  close: () => void;
  toEdit: string[];
  team: string;
  onFinished: (id: string[], collection: string) => void;
}

export interface IState {
  at: number;
  finished: boolean;
  running: boolean;
  handled: string[];
  errors: string[];
}

let MOUNTED = true;
const DELAY = 1000;

async function updateOwner(
  state: IState,
  setState: (state: IState) => void,
  collection: string,
  toEdit: string[],
  owner: string,
  onFinished: (newState: IState) => void
) {
  const newState = { ...state, running: true };

  if (!state.running) setState(newState);

  const id = toEdit[state.at];
  try {
    const response = await Artwork.update(
      { owner: { "!add": [owner] } },
      {
        id,
        collection: collection,
      }
    );
    if (!response.isSuccess()) newState.errors.push(id);
  } catch (error) {
    newState.errors.push(id);
  }

  if (MOUNTED) {
    newState.at++;
    const finished = !(newState.at < toEdit.length);

    setState({ ...newState, finished });

    if (!finished)
      setTimeout(
        () =>
          updateOwner(
            newState,
            setState,
            collection,
            toEdit,
            owner,
            onFinished
          ),
        DELAY
      );
    else {
      onFinished(newState);
    }
  }
}
async function updateLocation(
  state: IState,
  setState: (state: IState) => void,
  collection: string,
  toEdit: string[],
  location: string,
  onFinished: (newState: IState) => void
) {
  const newState = { ...state, running: true };

  if (!state.running) setState(newState);

  const id = toEdit[state.at];
  try {
    const result = await Artwork.get(
      {
        id,
        collection: collection,
      },
      ["location"],
      1
    );

    if (result.isSuccess()) {
      const response = result.getResult();
      let latest: string | undefined = undefined;
      if (response.data.length === 1 && response.data[0].location)
        latest = getSortedLocations(response.data[0].location)[0];

      const set: IArtworkSet = {
        location: {
          "!add": [location],
        },
        currentLocation: locationToObject(getCurrentLocation(location, latest))
          .location,
      };

      const updateResult = await Artwork.update(set, {
        id,
        collection: collection,
      });

      if (!updateResult.isSuccess()) newState.errors.push(id);
    } else newState.errors.push(id);
  } catch (error) {
    newState.errors.push(id);
  }

  if (MOUNTED) {
    newState.at++;
    const finished = !(newState.at < toEdit.length);

    setState({ ...newState, finished });

    if (!finished)
      setTimeout(
        () =>
          updateLocation(
            newState,
            setState,
            collection,
            toEdit,
            location,
            onFinished
          ),
        DELAY
      );
    else {
      onFinished(newState);
    }
  }
}
async function updateCollection(
  state: IState,
  setState: (state: IState) => void,
  collection: string,
  toEdit: string[],
  newCollection: string,
  onFinished: (newState: IState) => void
) {
  const newState = { ...state, running: true };

  if (!state.running) setState(newState);

  const id = toEdit[state.at];
  try {
    const result = await Artwork.get(
      {
        id,
        collection: collection,
      },
      ["artistId"],
      1
    );

    if (result.isSuccess()) {
      const response = result.getResult();
      let artistId: string | undefined = undefined;
      if (response.data.length === 1 && response.data[0].artistId)
        artistId = response.data[0].artistId;

      const set: IArtworkSet = {
        collection: newCollection,
        artistId: artistId,
      };

      const updateResult = await Artwork.update(set, {
        id,
        collection: collection,
      });

      if (!updateResult.isSuccess()) newState.errors.push(id);
      else newState.handled.push(id);
    } else newState.errors.push(id);
  } catch (error) {
    newState.errors.push(id);
  }

  if (MOUNTED) {
    newState.at++;
    const finished = !(newState.at < toEdit.length);

    setState({ ...newState, finished });

    if (!finished)
      setTimeout(
        () =>
          updateCollection(
            newState,
            setState,
            collection,
            toEdit,
            newCollection,
            onFinished
          ),
        DELAY
      );
    else {
      onFinished(newState);
    }
  }
}

export default function BulkUpdate(props: IProps) {
  const i18n = useI18n("artworks_bulk_update");

  const [state, setState] = useState<IState>({
    at: 0,
    finished: false,
    running: false,
    handled: [],
    errors: [],
  });

  const hasElements = props.toEdit.length > 0;

  useEffect(() => {
    MOUNTED = true;
    return () => {
      MOUNTED = false;
    };
  });

  return (
    <LightBox
      variant="primary"
      title={i18n.get("title")}
      open={true}
      onClose={props.close}
    >
      {!hasElements && (
        <>
          <Typo variant="h1">{i18n.get("no_title")}</Typo>
          <Typo variant="p">{i18n.get("no_desc")}</Typo>
          <Row horizontalAlign="right">
            <Button
              xs="100px"
              onClick={() => {
                props.close();
              }}
            >
              {i18n.get("close")}
            </Button>
          </Row>
        </>
      )}
      {hasElements && !state.running && !state.finished && (
        <Tabs
          tabs={[
            i18n.get("owner"),
            i18n.get("location"),
            i18n.get("collection"),
          ]}
        >
          <NewOwner
            add={async function (
              name: string,
              date: [string, string, string],
              description: string
            ): Promise<boolean> {
              const newOwner = `${date
                .reverse()
                .join("-")}|${name}|${description}`;
              updateOwner(
                state,
                setState,
                props.collection,
                props.toEdit,
                newOwner,
                (newState: IState) => {
                  setState({ ...newState, finished: true });
                }
              );
              //stops component from closing after finish
              return false;
            }}
            close={props.close}
          />
          <NewLocation
            add={async function (
              location: string,
              date: [string, string, string],
              description: string
            ): Promise<boolean> {
              await updateLocation(
                state,
                setState,
                props.collection,
                props.toEdit,
                `${date.reverse().join("-")}|${location}|${description}`,
                (newState: IState) => {
                  setState({ ...newState, finished: true });
                }
              );
              //stops component from closing after finish
              return false;
            }}
            onClose={props.close}
          />
          <BulkCollection
            onChange={function (collection: string): void {
              updateCollection(
                state,
                setState,
                props.collection,
                props.toEdit,
                collection || props.team,
                (newState: IState) => {
                  setState({ ...newState, finished: true });
                  props.onFinished(newState.handled, collection || props.team);
                }
              );
            }}
            onCancel={props.close}
          />
        </Tabs>
      )}
      {hasElements && state.running && !state.finished && (
        <>
          <Typo variant="h1">{i18n.get("updating")}...</Typo>
          <Row>
            <ProgressSpinner at={state.at} max={props.toEdit.length} />
          </Row>
          <Row horizontalAlign="right" spacing={8}>
            <Button
              xs="100px"
              onClick={() => {
                props.close();
              }}
            >
              {i18n.get("cancel")}
            </Button>
          </Row>
        </>
      )}
      {hasElements && state.finished && (
        <>
          <Typo variant="h1">{i18n.get("finished")}!</Typo>
          {state.errors.length === 0 && (
            <Typo variant="p">{i18n.get("finished_desc")}.</Typo>
          )}
          {state.errors.length !== 0 && (
            <>
              <Typo variant="p">{i18n.get("finished_error_desc")}.</Typo>
              <ul>
                {state.errors.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </>
          )}
          <Row horizontalAlign="right" spacing={8}>
            <Button
              xs="100px"
              onClick={() => {
                props.close();
              }}
            >
              {i18n.get("close")}
            </Button>
          </Row>
        </>
      )}
    </LightBox>
  );
}
