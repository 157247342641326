import React, { useEffect, useState } from "react";
import { useI18n, LightBox, Typo, Row, Button, Col } from "@maxeb/admin-ui";
import ProgressSpinner from "../../../Shared/ProgressSpinner";
import {
  Artwork,
  Category,
  IArtwork,
  ICategory,
  ITheme,
  Technique,
  Theme,
} from "@maxeb/art-sdk";

export interface IProps {
  toExport: string[];
  collection: string;
  close: () => void;
}

export interface IState {
  inited: boolean;
  at: number;
  artworks: string[][];
  finished: boolean;
  error: boolean;
}

let MOUNTED = false;
const DELAY = 1000;

const firstRow = [
  "id",
  "title",
  "artist",
  "artistId",
  "customId",
  "themeId",
  "theme",
  "categoryId",
  "category",
  "techniqueId",
  "technique",
  "year",
  "width",
  "height",
  "depth",
  "weight",
  "description",
  "signed",
  "dated",
  "frame",
  "available",
  "color",
  "reservedFrom",
  "reservedUntil",
  "reservedFor",
  "reservedReason",
  "sellingPrice",
  "proprietor",
  "source",
  "purchasingPrice",
  "purchasingDate",
  "insurer",
  "insurerPolicyNumber",
  "insurerValue",
  "team",
  "collection",
];

async function parseArtwork(
  artwork: IArtwork,
  categories: { [key: string]: ICategory },
  themes: { [key: string]: ITheme }
) {
  return Promise.all(
    firstRow.map(async (field) => {
      const value = artwork[field as keyof IArtwork];

      if (typeof value === "string") return value;
      else if (typeof value === "number" || typeof value === "boolean")
        return value.toString();
      else if (field === "theme" && artwork.themeId)
        return themes[artwork.themeId]?.name || "";
      else if (field === "category" && artwork.categoryId)
        return categories[artwork.categoryId]?.name || "";
      else if (
        field === "technique" &&
        artwork.categoryId &&
        artwork.techniqueId
      )
        return (
          (await getTechniqueName(artwork.techniqueId, artwork.categoryId)) ||
          ""
        );
      else if (typeof value === "undefined") return "";
      else throw Error("Artwork field " + field + " not parseable to string");
    })
  );
}

async function getTechniqueName(id: string, category: string) {
  const result = await Technique.get(
    { id, categoryId: category },
    ["id", "name", "nameDe"],
    20,
    "secondaryIndex",
    "ASC"
  );

  return result.getResult().data[0]?.name || "";
}

async function get(
  toExport: string[],
  at: number,
  collection: string,
  categories: { [key: string]: ICategory },
  themes: { [key: string]: ITheme },
  state: IState,
  setState: (state: IState) => void
): Promise<any> {
  const finished = at >= toExport.length;
  setState({ ...state, at: at + 1, inited: true });

  if (!finished) {
    try {
      const id = toExport[at];
      const result = await Artwork.get(
        { id, collection },
        [
          "id",
          "title",
          "artist",
          "artistId",
          "customId",
          "themeId",
          "categoryId",
          "techniqueId",
          "year",
          "width",
          "height",
          "depth",
          "weight",
          "description",
          "signed",
          "dated",
          "frame",
          "available",
          "color",
          "reservedFrom",
          "reservedUntil",
          "reservedFor",
          "reservedReason",
          "sellingPrice",
          "proprietor",
          "source",
          "purchasingPrice",
          "purchasingDate",
          "insurer",
          "insurerPolicyNumber",
          "insurerValue",
          "team",
          "collection",
        ],
        1
      );

      const newState = { ...state, inited: true };
      if (result.isSuccess()) {
        const artwork = result.getResult().data[0];
        newState.artworks.push(await parseArtwork(artwork, categories, themes));
        newState.at = at;
        if (MOUNTED) {
          setState(newState);
          return setTimeout(
            () =>
              get(
                toExport,
                at + 1,
                collection,
                categories,
                themes,
                newState,
                setState
              ),
            DELAY
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
    console.error("Error at: ", at, toExport[at]);
    if (MOUNTED) return setState({ ...state, error: true });
  } else {
    const newState = { ...state, inited: true };
    newState.finished = true;
    if (MOUNTED) setState(newState);
  }
}

function download(state: IState) {
  const resultsCSV = [[...firstRow], ...state.artworks];
  const escapedRow = resultsCSV.map((row) => {
    const escaped = row.map((cell) => {
      if (cell.includes('"') || cell.includes(";") || cell.includes("\n")) {
        const escaped = cell.replaceAll('"', '""');
        return '"' + escaped + '"';
      } else {
        return cell;
      }
    });
    return escaped.join(";");
  });

  const element = document.createElement("a");
  const file = new Blob([escapedRow.join("\n")], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = "artworks_export.csv";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

async function prepare(
  toExport: string[],
  at: number,
  collection: string,
  state: IState,
  setState: (state: IState) => void
) {
  const [categories, themes] = await Promise.all([
    Category.get({}, ["id", "name", "nameDe"], 20, "secondaryIndex", "ASC"),
    Theme.get({}, ["id", "name", "nameDe"], 20, "secondaryIndex", "ASC"),
  ]);

  const categorieLookUp: { [key: string]: ICategory } = {};
  categories.getResult().data.forEach((element) => {
    categorieLookUp[element.id || ""] = element;
  });

  const themesLookUp: { [key: string]: ITheme } = {};
  themes.getResult().data.forEach((element) => {
    themesLookUp[element.id || ""] = element;
  });

  get(toExport, at, collection, categorieLookUp, themesLookUp, state, setState);
}

export default function BulkExport(props: IProps) {
  const i18n = useI18n("artworks_bulk_export");
  const [state, setState] = useState<IState>({
    inited: false,
    at: 0,
    artworks: [],
    finished: false,
    error: false,
  });

  useEffect(() => {
    MOUNTED = true;
    if (!state.inited && MOUNTED) {
      prepare(props.toExport, state.at, props.collection, state, setState);
    }
    return () => {
      MOUNTED = false;
    };
  }, [state, props.collection, props.toExport]);

  return (
    <LightBox
      variant="primary"
      title={i18n.get("title")}
      open={true}
      onClose={props.close}
    >
      {!state.finished && (
        <>
          <Row>
            <ProgressSpinner
              at={state.artworks.length}
              max={props.toExport.length}
            />
          </Row>
          <Row horizontalAlign="center">
            <Col xs="auto">
              <Typo variant="p">Getting Artworks...</Typo>
            </Col>
          </Row>
          <Row horizontalAlign="right">
            <Button xs="100px" onClick={() => props.close()}>
              Cancel
            </Button>
          </Row>
        </>
      )}
      {state.finished && (
        <>
          <Typo variant="h1">{i18n.get("finished")}!</Typo>
          <Typo variant="p">{i18n.get("finished_desc")}.</Typo>
          <Row horizontalAlign="right" spacing={8}>
            <Button
              xs="100px"
              onClick={() => {
                props.close();
              }}
            >
              {i18n.get("close")}
            </Button>
            <Button
              xs="100px"
              primary
              onClick={() => {
                download(state);
              }}
            >
              {i18n.get("download")}
            </Button>
          </Row>
        </>
      )}
    </LightBox>
  );
}
