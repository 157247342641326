import React, { useState } from "react";
import { AdminPage, ITheme, useClasses, useI18n } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintBrush } from "@fortawesome/pro-light-svg-icons";
import Search from "./Search";
import ArtworkEdit from "../Edit";
import { useArtworks } from "../../../Hooks/Artworks/List";
import TableView from "./TableView";
import Actions from "./Actions";
import Add from "../Add";
import GridView from "./GridView";

export interface IProps {
  team: string;
}

const styles = (theme: ITheme) => ({
  main: {
    height: "calc(100vh - 251px) !important",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 123px) !important",
    },
    "@media print": {
      display: "none",
    },
  },
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  active: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.get("default"),
    "@media print": {
      position: "static",
    },
  },
});

export default function Artworks(props: IProps) {
  const classes = useClasses(styles);
  const i18n = useI18n("artworks");

  const [view, setView] = useState<"table" | "grid">("grid");

  const [searchOpen, setSearchOpen] = useState(false);

  const [artworks, actions] = useArtworks({
    team: props.team,
    collection: props.team,
  });
  const { status, active } = artworks;

  const [add, setAdd] = useState<boolean>(false);

  return (
    <AdminPage
      title={i18n.get("artworks")}
      state={
        status === "init" ? "pending" : status === "pending" ? "ok" : status
      }
      icon={<FontAwesomeIcon className={classes.icon} icon={faPaintBrush} />}
      breadcrumbs={[
        { label: i18n.get("artworks") },
        { label: i18n.get("all") },
      ]}
      actions={
        <Actions
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          setAdd={setAdd}
          setView={setView}
          view={view}
        />
      }
      override={{ overlay: classes.overlay, main: classes.main }}
      subhead={
        <Search
          respOpen={searchOpen}
          data={artworks.search}
          team={props.team}
          onChange={actions.search}
        />
      }
      overlayPages={
        active !== null
          ? [
              <div className={classes.active}>
                <ArtworkEdit
                  id={active.id || ""}
                  collection={active.collection}
                  team={artworks.team}
                  onChang={(artworks) => actions.updateActive(artworks)}
                  close={(isDeleted) => {
                    if (isDeleted) actions.updateActive(null);
                    else actions.active(null, null);
                  }}
                />
              </div>,
            ]
          : undefined
      }
    >
      <Add
        open={add}
        setOpen={(value) => {
          setAdd(value);
        }}
        collection={props.team}
        setNewActive={actions.setNewActive}
      />
      {view === "grid" && <GridView artworks={artworks} actions={actions} />}
      {view === "table" && (
        <TableView
          status={status}
          useArtworks={[artworks, actions]}
          team={props.team}
        />
      )}
    </AdminPage>
  );
}
