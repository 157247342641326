import { Col, ITheme, Row, Typo, useClasses, useI18n } from "@maxeb/admin-ui";
import React from "react";
import { Actions, State } from "../../../Hooks/Artworks/List";
import Card from "../../Shared/Card";
import { faPaintBrush, faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IsVisible from "../../Shared/IsVisible";

export interface Props {
  artworks: State;
  actions: Actions;
}

const styles = (theme: ITheme) => ({
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  filter: {
    position: "fixed",
    zIndex: 4,
  },
  active: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.get("default"),
  },
  pending: {
    color: theme.palette.get("primary"),
    fontSize: "5rem",
    textAlign: "center",
    padding: "32px",
  },
  link: {
    textDecoration: "none",
  },
});

export default function GridView(props: Props) {
  const classes = useClasses(styles);

  const { actions } = props;
  const { status, data, next } = props.artworks;
  const i18n = useI18n("artworks");

  return (
    <Row spacing={16} vertical root={0}>
      {actions.filter().map((artwork, index) => (
        <Col
          key={`artist-${artwork.id}`}
          xs={10}
          sm={5}
          md={10 / 3}
          lg={10 / 4}
          xl={10 / 5}
        >
          <a
            className={classes.link}
            href={`/artworks/${artwork.collection}/${artwork.id}`}
            onClick={(e) => {
              e.preventDefault();
              actions.active(artwork, artwork.index || index);
              window.history.pushState(
                {},
                "",
                `/artworks/${artwork.collection}/${artwork.id}`
              );
            }}
          >
            <Card
              name={artwork.artist}
              subscript={artwork.title}
              icon={faPaintBrush}
              thumb={
                artwork.thumb && {
                  ...artwork.thumb,
                  url: artwork.thumb["xs-url"],
                }
              }
            />
          </a>
        </Col>
      ))}
      {status === "ok" && data.length === 0 && (
        <Row>
          <Col lg={5}>
            <Typo variant="h1">{i18n.get("no_artworks")}</Typo>
            <Typo variant="p">{i18n.get("no_artworks_desc")}</Typo>
          </Col>
        </Row>
      )}
      {status === "pending" && (
        <Col
          xs={10}
          sm={5}
          md={10 / 3}
          lg={10 / 4}
          xl={10 / 5}
          override={{ col: classes.pending }}
        >
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </Col>
      )}
      <Col xs={10} sm={5} md={10 / 3} lg={10 / 4} xl={10 / 5}>
        <IsVisible
          onChange={(isVisible) => {
            if (isVisible && status === "ok" && next) actions.next();
          }}
        />
      </Col>
    </Row>
  );
}
