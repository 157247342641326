import React from "react";
import {
  AdminPage,
  ITheme,
  useClasses,
  Row,
  Col,
  usePathVariables,
  Block,
  useI18n,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintBrushAlt } from "@fortawesome/pro-light-svg-icons";
import Thumb from "./Thumb";
import Data from "./Data";
import Delete from "../../Shared/Delete";
import Selling from "./Selling";
import Tabs from "../../Shared/Tabs";
import Proprietor from "./Proprietor";
import Collection from "./Collection";
import Images from "./Images";
import Log from "./Log";
import { useArtwork } from "../../../Hooks/Artworks/Edit";
import Mockup from "./Mockup";
import { IArtwork } from "@maxeb/art-sdk";
import History from "./History";
import Documents from "./Documents";
import Actions from "./Actions";
import QR from "./QRCode";

export interface IProps {
  team: string;
  id?: string;
  collection?: string;
  onChang?: (artwork: IArtwork) => void;
  close?: (isDeleted?: boolean) => void;
}

const styles = (theme: ITheme) => ({
  main: {
    [theme.breakpoints.up("md")]: { height: "calc(100vh - 204px) !important" },
    "@media print": {
      height: "auto !important",
    },
  },
  overlay: {
    padding: "132px",
    backgroundColor: "rgba(236, 236, 236, 0.95) !important",
  },
  alignCenter: {
    textAlign: "center",
  },
  row: {
    [theme.breakpoints.up("md")]: {
      width: "330px",
    },
  },
  icon: {
    fontSize: "4rem",
    margin: "0px 16px 0px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      margin: "0px 4px 0px 0px",
    },
  },
  link: {
    textDecoration: "none",
  },
});

export default function ArtworkEdit(props: IProps) {
  const classes = useClasses(styles);
  const params = usePathVariables();
  const i18n = useI18n("artwork_edit");

  const [artwork, actions] = useArtwork({
    id: props.id || params.id,
    collection: props.collection || params.collection,
    onChange: props.onChang,
  });

  const { status, data } = artwork;
  const back =
    props.close &&
    (() => {
      if (window?.history?.pushState)
        window.history.pushState({}, "", `/artworks`);
      if (props.close) props.close();
    });

  return (
    <AdminPage
      icon={<FontAwesomeIcon className={classes.icon} icon={faPaintBrushAlt} />}
      title={i18n.get("artworks")}
      state={status === "init" ? "pending" : status}
      breadcrumbs={[
        { label: i18n.get("artworks"), onClick: props.close },
        { label: i18n.get("edit") },
      ]}
      actions={<Actions actions={actions} close={back} />}
      override={{ overlay: classes.overlay, main: classes.main }}
    >
      {data.id && (
        <Row spacing={16} vertical root={0}>
          <Col lg={7.5}>
            <Row spacing={16} vertical root={0}>
              <Col lg="410px" print={7}>
                <Block>
                  <Tabs
                    tabs={[
                      i18n.get("thumb"),
                      i18n.get("images"),
                      i18n.get("mockup"),
                      "QR",
                    ]}
                  >
                    <Thumb
                      id={data.id}
                      collection={data.collection || ""}
                      thumb={data.thumb}
                      setPending={() => {
                        actions.pending();
                      }}
                      setSuccess={() => {
                        actions.reload();
                      }}
                    />
                    <Images
                      id={data.id}
                      collection={data.collection || ""}
                      images={data.images}
                      setPending={() => {
                        actions.pending();
                      }}
                      setSuccess={() => {
                        actions.reload();
                      }}
                    />
                    <Mockup
                      id={data.id}
                      collection={data.collection || ""}
                      value={data.mockup}
                      thumb={data.thumb}
                      width={data.width}
                      height={data.height}
                      setPending={() => {
                        actions.pending();
                      }}
                      setSuccess={() => {
                        actions.reload();
                      }}
                    />
                    <QR
                      id={data.id}
                      collection={data.collection || ""}
                      name={data.customId}
                    />
                  </Tabs>
                </Block>
              </Col>
              <Col lg="calc(100% - 410px)">
                <Data
                  data={data}
                  errors={artwork.errors}
                  onChange={actions.setData}
                />
              </Col>
              <Col lg={4}>
                <Selling
                  data={data}
                  errors={artwork.errors}
                  onChange={actions.setData}
                />
              </Col>
              <Col lg={6}>
                <Block>
                  <Tabs
                    tabs={[
                      i18n.get("proprietor"),
                      i18n.get("collection"),
                      i18n.get("documents"),
                    ]}
                  >
                    <Proprietor
                      data={data}
                      errors={artwork.errors}
                      onChange={actions.setData}
                    />
                    <Collection
                      data={data}
                      onChange={(value) => {
                        actions.setCollection(value);
                      }}
                    />
                    <Documents
                      data={data}
                      setOk={() => {
                        actions.ok();
                      }}
                      setPending={() => {
                        actions.pending();
                      }}
                      setSuccess={() => {
                        actions.reload();
                      }}
                    />
                  </Tabs>
                </Block>
              </Col>
            </Row>
          </Col>
          <Col lg={2.5} stretch>
            <Block>
              <Tabs tabs={[i18n.get("history"), i18n.get("log")]}>
                <History
                  owner={data.owner}
                  location={data.location}
                  condition={data.condition}
                  addOwner={async function (
                    name: string,
                    date: [string, string, string],
                    description: string
                  ) {
                    return actions.addOwner(
                      `${date.reverse().join("-")}|${name}|${description}`
                    );
                  }}
                  deleteOwner={async function (id: string) {
                    actions.removeOwner(id);
                  }}
                  addCondition={async function (
                    name: string,
                    date: [string, string, string],
                    description: string
                  ) {
                    return actions.addCondition(
                      `${date.reverse().join("-")}|${name}|${description}`
                    );
                  }}
                  deleteCondition={async function (id: string) {
                    return actions.removeCondition(id);
                  }}
                  addLocation={async function (
                    location: string,
                    date: [string, string, string],
                    description: string
                  ) {
                    return actions.addLocation(
                      `${date.reverse().join("-")}|${location}|${description}`
                    );
                  }}
                  deleteLocation={async function (id: string) {
                    return actions.removeLocation(id);
                  }}
                />
                <Log id={data.id} />
              </Tabs>
            </Block>
          </Col>
          <Delete
            name={`Werk - ${data.title}`}
            open={artwork.confirmDelete}
            onClose={() => {
              actions.setConfirmDelete(false);
            }}
            onDelete={async () => {
              await actions.remove();
              if (props.close) props.close(true);
            }}
          />
        </Row>
      )}
    </AdminPage>
  );
}
