import React from "react";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { init } from "@maxeb/admin-ui";
import { options as IDPOptions } from "@maxeb/idp-sdk";
import { options as ArtOptions } from "@maxeb/art-sdk";
import { createRoot } from "react-dom/client";
import { apikey } from "./secrets";

IDPOptions.setApiKey(apikey);
ArtOptions.setApiKey(apikey);
IDPOptions.setUrl("https://idp.webcollector.at");
ArtOptions.setUrl("https://art.webcollector.at");

const palette = {
  primary: "#5c90d2",
  secondary: "#2c689c",
  tertiary: "#212a37",
  danger: "#b7433f",
  success: "#559554",
  warning: "#e8db05",
  default: "#ececec",
  grey: "#e0e0e0",
  darkgrey: "#7d7d7d",
  font: "#3e3e3e",
  background: "#ececec",
  formLabel: "#797979",
  formBackground: "#ececec",
  formBackgroundActive: "#e0e0e0",
  mediumGrey: "#a8a8a8",
  section: "#717171",
};

init({ palette: palette });

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
